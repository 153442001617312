<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm" title="상세">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="hazard"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveHazard"
            @btnCallback="saveHazardCallback" />
          <c-btn 
            v-show="completeBtnEditable" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="hazard"
            mappingType="PUT"
            label="유해요인 조사완료" 
            icon="check"
            @beforeAction="completeHazard"
            @btnCallback="completeHazardCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-radio
            :required="true"
            :editable="editable"
            codeGroupCd="HEA_MUSCLE_HAZARD_RESEARCH_CD"
            label="조사구분"
            name="heaInvestigationTypeCd"
            v-model="hazard.heaInvestigationTypeCd">
          </c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            default="today"
            label="조사일시"
            name="investigationDate"
            v-model="hazard.investigationDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-field
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :data="hazard"
            deptValue="deptCd"
            type="dept_user"
            label="조사자"
            name="userId"
            v-model="hazard.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="공정"
            name="processName"
            v-model="hazard.processName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="단위작업"
            name="sopName"
            v-model="hazard.sopName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="7"
            label="비고"
            name="remark"
            v-model="hazard.remark">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="첨부파일">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-table
            title="작업장 상황 조사"
            :columns="grid1.columns"
            :data="hazard.situations"
            :editable="editable&&!disabled"
            :gridHeight="gridHeight"
            hideBottom
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-table
            title="작업부하 및 작업빈도"
            :columns="grid2.columns"
            :data="hazard.heavyWorkIncludes"
            :gridHeight="gridHeight"
            :editable="editable&&!disabled"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
            :usePaging="false"
            @callbackProxy="callbackProxy"
          >
          </c-table>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'investiWork',
  props: {
    hazard: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSopStepCd: '',  // 진행상태
        processCd: '',  // 공정코드
        processName: '',  // 공정명
        mdmSopId: '',  // 안전작업 표준 일련번호
        sopName: '',  // 세부공정(작업) 명
        heaInvestigationTypeCd: '',  // 조사구분
        investigationDate: '',  // 조사일시
        deptCd: '',  // 조사자 부서
        userId: '',  // 조사자 id
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        heavyWorks: [], // 부담작업
        heavyWorkIncludes: [], // 부담작업 결과 O
        heavyWorkHazards: [], // 유해요인
        workers: [], // 작업자
        situations: [], // 상황조사
        results: [], // 결과표
        imprs: [], // 개선
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'HEA_MUSCLE_HAZARD',
        taskKey: '',
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'heaMuscleSopSituationGubunName',
            field: 'heaMuscleSopSituationGubunName',
            label: '구분',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'changeFlag',
            field: 'changeFlag',
            label: '변화유무',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'check',
            'true': 'Y',
            'false': 'N',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '내용요약',
            align: 'left',
            sortable: false,
            type: 'text',
          },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'heavyWorkName',
            field: 'heavyWorkName',
            label: '부담작업No',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'detailJobContents',
            field: 'detailJobContents',
            label: '작업내용',
            align: 'left',
            sortable: false,
            style: 'width:180px',
          },
          {
            name: 'workload',
            field: 'workload',
            label: '작업부하(A)',
            align: 'center',
            style: 'width:40px',
            sortable: false
          },
          {
            name: 'workFrequency',
            field: 'workFrequency',
            label: '작업빈도(B)',
            align: 'center',
            style: 'width:40px',
            sortable: false
          },
          {
            name: 'totalScore',
            field: 'totalScore',
            label: '총점수(AxB)',
            align: 'center',
            style: 'width:70px',
            sortable: false,
            required: true,
            type: 'proxy',
            component: () => import(`${'./jobRiskPop.vue'}`)
          },
        ],
      },
      editable: true,
      isSave: true,
      isComplete: true,
      saveUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    completeBtnEditable() {
      return this.editable 
        && !this.disabled 
    },
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 450) + 'px' : '500px'
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.muscleSystem.muscleSop.update.url
      this.completeUrl = transactionConfig.hea.muscleSystem.muscleSop.complete.url
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    callbackProxy(data, props, col) {
      if (col.name === 'totalScore') {
        this.$set(this.hazard.heavyWorkIncludes[props.rowIndex], 'workFrequency', data.frequency)
        this.$set(this.hazard.heavyWorkIncludes[props.rowIndex], 'workload', data.strength)
        this.$set(this.hazard.heavyWorkIncludes[props.rowIndex], 'totalScore', data.total)
      }
      if (this.hazard.heavyWorkIncludes[props.rowIndex].editFlag !== 'C') {
        this.$set(this.hazard.heavyWorkIncludes[props.rowIndex], 'editFlag', 'U')
      }
    },
    saveHazard() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid2.columns, this.hazard.heavyWorkIncludes)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.hazard.regUserId = this.$store.getters.user.userId
                this.hazard.chgUserId = this.$store.getters.user.userId
  
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveHazardCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
    completeHazard() {      
      /**
       * 1. 유해요인 조사는 이뤄졌는지?
       * 2. 결과표는 완성되었는지?
       * 
       *  ** 우선은 유효성 검사 하지 않음
       */
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.hazard.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeHazardCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
  }
};
</script>